<script>
import { ref, onMounted, computed, inject } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
export default {
  name: 'eventListMaster',
  setup() {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const userName = ref('');
    const token = ref('');
    const pieceEvents = ref([]);
    const page = 1; // 페이지 번호
    const limit = 6; // 한 페이지에 표시할 항목 수
    const master_event_id = ref('');
    const masterTitle = ref('');


    function format_status(statusValue) {
      switch (statusValue) {
        case 0:
          return { 'statusText': '준비중', 'statusClass': 'on_ready' };
        case 1:
          return { 'statusText': '진행중', 'statusClass': 'on_going' };
        case 2:
          return { 'statusText': '종료', 'statusClass': 'finished' };
        default:
          return null;
      }
    }

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
        userName.value = store.state.userStore.userName;
        token.value = store.state.userStore.token;
        try {
          master_event_id.value = router.currentRoute.value.params.eventId

          // axios를 사용하여 이벤트 리스트를 가져옵니다.
          const response = await axios.get('/event/read_master', {
            params: {
              master_event_id: master_event_id.value
            },
            headers: {
              Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
            },
          });

          // 요청이 성공했고 결과가 true인 경우
          if (response.data.result === true) {
            pieceEvents.value = response.data.master_event.piece_event_list;
            masterTitle.value = response.data.master_event.title;
            store.commit('eventStore/setEventTitle', masterTitle.value);
          } else {
            // 요청이 성공했지만 결과가 false인 경우
            // 이벤트를 불러오지 못했다는 알림창 띄우기
            alert('이벤트를 불러오지 못했습니다.');
          }
        } catch (error) {
          // 요청이 실패한 경우
          console.error('이벤트 리스트를 가져오는 중에 오류가 발생했습니다:', error);
        }
      }
    });

    onMounted(async () => {
      if (checkPermissionsAndRedirect()) {
        await loadEventDetails();
      }
    });

    const checkPermissionsAndRedirect = () => {
      if (!isAuthenticated.value) {
        router.push({ name: "home" }); // 비인증 사용자를 홈으로 리다이렉션
        return false;
      }
      return true;
    };

    const loadEventDetails = async () => {
      userName.value = store.state.userStore.userName;
      token.value = store.state.userStore.token;
      master_event_id.value = router.currentRoute.value.params.eventId;
      store.commit('eventStore/setMasterId', master_event_id.value);
      store.commit('eventStore/setEventDate', '');

    };

    return { isAuthenticated, userName, pieceEvents, format_status, masterTitle, master_event_id };
  },
}


</script>

<template>
  <div id="eventListMaster">
    <div class="left">
      <div class="title_wrap">
        <div class="title_top ds_flex">
          <p class="master_icon"><i class="icon-crown"></i></p>
          <router-link :to="`/admin/event/setting/master/${master_event_id}`" class="icon_box"><i
              class="icon-edit"></i></router-link>
        </div>
        <p class="size_36 b_700 event_title">{{ masterTitle }}</p>
        <div class="master_btn_wrap">
          <router-link :to="{ name: 'masterSurveyDashboard' }" class="inline_cont_btn">전체 실적현황</router-link>
          <router-link :to="{ name: 'masterReception' }" class="inline_cont_btn">전체 영접현황</router-link>
          <router-link :to="{ name: 'masterInfoDesk' }" class="inline_cont_btn">인포데스크</router-link>
          <!-- <router-link :to="{ name: '' }" class="inline_cont_btn">전체 상담운영</router-link> -->
        </div>
      </div>
    </div>
    <div class="right">
      <div class="event_wrap">
        <div class="event_card_wrap">
          <router-link v-for="(item, index) in pieceEvents" :key="index" class="event_card"
            :to="`/admin/event/setting/${item.event_id}`">
            <div class="event_card_top">
              <div class="event_card_top_left">
                <p class="master_icon" v-if="item.event_type == 'master'"><i class="icon-crown"></i></p>
                <p class="event_status" :class="format_status(item.status).statusClass" v-if="item.status">{{
                  format_status(item.status).statusText }}</p>
              </div>
              <div class="event_card_top_right">
                <p class="event_pm" v-if="item.event_type == 'piece'">{{ item.pm_text }}</p>
                <p class="event_bookmark"><i class="icon-bookmark"></i></p>
              </div>
            </div>
            <p class="event_title">{{ item.title }}</p>
            <div class="event_card_bottom">
              <template v-if="item.event_type == 'master'">
                <p class="event_info"><i class="icon-person"></i>{{ item.company }}</p>
                <p class="event_info"><i class="icon-manual2"></i>{{ item.piece_text }}</p>
                <p class="event_info"><i class="icon-pm"></i>{{ item.pm_text }}</p>
              </template>
              <template v-else>
                <p class="event_info"><i class="icon-date"></i>{{ item.start_date + " ~ " + item.end_date }}
                </p>
                <p class="event_info"><i class="icon-location"></i>{{ item.place }}</p>
                <p class="event_info"><i class="icon-person"></i>{{ item.company }}</p>
              </template>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
