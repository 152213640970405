<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import router from '@/router';

export default defineComponent({
  props: {
    showAddScheduleModal: Boolean
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();

    const eventId = computed(() => store.state.eventStore.eventId);
    const selectDate = computed(() => store.state.eventStore.eventDate);
    const accountId = computed(() => store.state.userStore.accountId);
    
    const is_video_link = computed(() => store.state.eventStore.isActiveOnlineLink);
    // 페이지 상태 및 토큰 관리
    const isAuthenticated = ref(false);
    const token = ref('');

    const closeModals = () => {
      newScheduleInfo.value = {};
      emit('close');
    }

    const newScheduleInfo = ref({}); // 스케줄 개별추가


    const dateTimeList = ref([]);
    const BoothList = ref([]);
    const userList_buyer = ref([]);
    const userList_inter = ref([]);
    const userList_seller = ref([]);

     // accountId와 일치하는 값을 자동으로 선택
     const selectInterpreterByAccountId = () => {
      const selectedUser = userList_inter.value.find(user => user.account_id === accountId.value);
      if (selectedUser) {
        newScheduleInfo.value.interpreter = selectedUser; // 일치하는 데이터를 v-model에 설정
      }
    };

    // STEP2. 이벤트 세팅 시 설정한 상담회 날짜, 시간 불러오기
    async function fetchDateTime() {
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/schedule/read_datetime_for_bis', {
          params: {
            piece_event_id: eventId.value,
            date: selectDate.value,
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          dateTimeList.value = response.data.datetime.map(item => ({
            ...item,
            start_time: item.start_time.slice(0, 5), // HH:MM 형식으로 변환
            end_time: item.end_time.slice(0, 5), // HH:MM 형식으로 변환
            optionLabel: `${item.start_time.slice(0, 5)} - ${item.end_time.slice(0, 5)}` // label로 사용할 computedLabel 추가
          }));
          fetchBoothList();
          fetchBISList();
        } else {
          console.log("스케줄 로드 실패")
        }
      } catch (error) {
      }
    }


    // 부스 조회 기능
    async function fetchBoothList() {
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/schedule/read_booths_for_bis', {
          params: {
            piece_event_id: eventId.value,
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          BoothList.value = response.data.booths;
        } else {
          console.log("스케줄 로드 실패")
        }
      } catch (error) {
      }
    }

    // BIS 사용자 리스트 API - 이벤트에 등록된 BIS만 가져오기
    async function fetchBISList() {
      try {
        const queryParams = new URLSearchParams({
          page: 1,
          limit: 10000
        });
        queryParams.append('account_type_list', 'buyer');
        queryParams.append('account_type_list', 'interpreter');
        queryParams.append('account_type_list', 'seller');
        queryParams.append('event_id', eventId.value);
        queryParams.append('event_type', 'PIECE');
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/user/read_list_for_bis?${queryParams.toString()}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          const allUsers = response.data.user_list;
          userList_buyer.value = allUsers.filter(user => user.account_type === 'BUYER');
          userList_inter.value = allUsers.filter(user => user.account_type === 'INTERPRETER');
          userList_seller.value = allUsers.filter(user => user.account_type === 'SELLER');
          selectInterpreterByAccountId()
        } else {
          alert('사용자를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('사용자 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }

    // 개별 스케줄 추가 API
    async function addSchedule() {
      try {
        const url = '/schedule/create_schedules_for_bis';
        const requestData = {
          piece_event_id: eventId.value,
          ptd_id: newScheduleInfo.value.time.ptd_id,
          ptb_id: newScheduleInfo.value.booth.ptb_id,
          buyer: {
            account_id: newScheduleInfo.value.buyer.account_id,
            account_type: newScheduleInfo.value.buyer.account_type,
            at_id: newScheduleInfo.value.buyer.at_id
          },
          interpreter: {
            account_id: newScheduleInfo.value.interpreter.account_id,
            account_type: newScheduleInfo.value.interpreter.account_type,
            at_id: newScheduleInfo.value.interpreter.at_id
          },
          seller: {
            account_id: newScheduleInfo.value.seller.account_id,
            account_type: newScheduleInfo.value.seller.account_type,
            at_id: newScheduleInfo.value.seller.at_id
          },
          link: '',
          memo: newScheduleInfo.value.memo,
          real_time: '',
          video_provider: 'OFF'
        };

        const response = await axios.post(url, requestData, {
          headers: {
            Authorization: `Bearer ${token.value}`,
            'Content-Type': 'application/json' // JSON 데이터 전송을 위한 헤더 설정
          }
        });

        if (response.data.result === true) {
          alert("스케줄이 추가되었습니다.")
          router.go()
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 개별추가 오류:', error);
      }
    }

    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
        fetchDateTime();
      }
      window.addEventListener("keyup", (event) => {
        if (event.code === "Escape") {
          closeModals();
        }
      });
    });
    return {
      addSchedule,
      newScheduleInfo,
      dateTimeList,
      userList_buyer,
      userList_inter,
      userList_seller,
      BoothList,
      is_video_link,
      closeModals
    }
  },
});
</script>

<template>
  <div id="addScheduleForBIS">
    <div class="overlay" v-if="showAddScheduleModal"></div>
    <transition name="slide-up">
      <div class="bottom_sheet" v-if="showAddScheduleModal">
        <div class="sheet_header">
          <p class="icon_box" @click="closeModals"><i class="icon-delete"></i></p>
        </div>
        <div class="sheet_content">
          <p class="size_20 b_700">스케줄 추가</p>
          <p class="size_14 sub_text">개별 스케줄을 추가할 수 있어요.</p>
          <div class="cont">
            <div class="input_cont">
              <label>부스명</label>
              <v-select :options="BoothList" v-model="newScheduleInfo.booth" label="booth_name" :reduce="item => item"
                :clearable="false" placeholder="비어있음"></v-select>
              </div>
              <div class="input_cont">
                <label>상담시간</label>
                <v-select :options="dateTimeList" v-model="newScheduleInfo.time" label="optionLabel" :reduce="item => item"
                  :clearable="false" placeholder="비어있음"></v-select>
            </div>
            <div class="input_cont">
              <label>바이어</label>
              <v-select :options="userList_buyer" v-model="newScheduleInfo.buyer" label="company" :reduce="item => item"
                :clearable="false" placeholder="비어있음"></v-select>
            </div>
            <div class="input_cont">
              <label>통역사</label>
              <v-select :options="userList_inter" v-model="newScheduleInfo.interpreter" label="full_name" placeholder="비어있음" :searchable="false" disabled></v-select>
            </div>
            <div class="input_cont">
              <label>셀러</label>
              <v-select :options="userList_seller" v-model="newScheduleInfo.seller" label="company"
                :reduce="item => item" :clearable="false" placeholder="비어있음"></v-select>
            </div>
            <div class="input_cont video">
              <label>상담링크</label>
              <input type="text" class="fake_textbox" />
              <p v-if="is_video_link" class="inline_cont_btn">생성</p>
            </div>
            <div class="input_cont">
              <label>비고</label>
              <textarea placeholder="내용을 작성해주세요." v-model="newScheduleInfo.memo"></textarea>
            </div>
          </div>
          <div class="btn_wrap ds_flex gap18 jus_end">
            <p class="block_main_btn active_btn w100" @click="addSchedule()">저장하기</p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
