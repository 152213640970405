<script>
import { ref, onMounted, computed, reactive, watch, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import router from '@/router';
import { Vue3Marquee } from 'vue3-marquee'


export default {
  name: 'HomeView',
  components: { Vue3Marquee },
  props: {
    windowSize: Object
  },
  setup(props) {
    const store = useStore(); // Vuex 스토어를 가져옵니다.
    const isAuthenticated = computed(() => store.state.userStore.isAuthenticated);
    const accountType = computed(() => store.state.userStore.accountType);

    // 풀페이지 변수
    const inMove = ref(false);
    const inMoveDelay = 400;
    const activeSection = ref(0);
    const offsets = ref([]);

    // 풀페이지 옵션 ----------------------------------------------------------------------
    // 섹션의 오프셋 계산
    const calculateSectionOffsets = () => {
      const sections = document.getElementsByTagName('section');
      offsets.value = Array.from(sections).map(section => section.offsetTop);
    };

    // 마우스 휠 이벤트 핸들러 (다른 브라우저)
    const handleMouseWheel = (e) => {
      if (!inMove.value) {
        if (activeSection.value === 3) { // Section 4일 때
          e.wheelDelta < -100 ? moveHorizontal("next") : e.wheelDelta > 100 && moveHorizontal("prev");
        } else {
          e.wheelDelta < -100 ? moveUp() : e.wheelDelta > 100 && moveDown();
        }
      }
      e.preventDefault();
    };

    // 마우스 휠 이벤트 핸들러 (Firefox)
    const handleMouseWheelDOM = (e) => {
      if (!inMove.value) {
        if (activeSection.value === 3) { // Section 4일 때
          e.detail > 0 ? moveHorizontal("next") : moveHorizontal("prev");
        } else {
          e.detail > 0 ? moveUp() : moveDown();
        }
      }
    };

    // 이전 섹션으로 이동
    const moveDown = () => {
      if (activeSection.value === 0) return;
      inMove.value = true;
      activeSection.value--;
      if (activeSection.value < 0) {
        activeSection.value = offsets.value.length - 1
      };

      // Section 5에서 Section 4로 돌아갈 때 슬라이드 1로 설정
      if (activeSection.value === 3) {
        activeHorizontalSlide.value = 0;
      }
      scrollToSection(activeSection.value, true);

    };

    // 다음 섹션으로 이동
    const moveUp = () => {
      inMove.value = true;
      activeSection.value++;
      if (activeSection.value > offsets.value.length - 1) activeSection.value = 0;
      scrollToSection(activeSection.value, true);
    };

    // 주어진 섹션으로 스크롤 이동
    const scrollToSection = (id, force = false) => {
      if (inMove.value && !force) return;
      activeSection.value = id;
      inMove.value = true;

      const section = document.getElementsByTagName('section')[id];
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }

      // Section 4로 이동하면 첫 번째 슬라이드로 이동
      if (id === 3) {
        activeHorizontalSlide.value = 0;
        scrollToHorizontalSlide(activeHorizontalSlide.value);
      }

      setTimeout(() => {
        inMove.value = false;
      }, inMoveDelay);
    };

    // 가로 슬라이드 옵션
    const activeHorizontalSlide = ref(0);
    const horizontalSlides = 4; // Section 4의 가로 슬라이드 개수
    const inMoveDelayHorizontal = 1000; // 1초간 멈춤


    const moveHorizontal = (direction) => {
      if (inMove.value) return; // 슬라이드가 이동 중이면 동작하지 않음
      inMove.value = true;

      if (direction === "next") {
        activeHorizontalSlide.value++;
        if (activeHorizontalSlide.value >= horizontalSlides) {
          // 마지막 슬라이드를 넘어가면 Section 5로 이동
          activeSection.value = 4;
          scrollToSection(4, true); // Section 5로 스크롤 이동
          activeHorizontalSlide.value = horizontalSlides - 1; // 마지막 슬라이드로 고정
        } else {
          scrollToHorizontalSlide(activeHorizontalSlide.value); // 다음 슬라이드로 이동
        }
      } else if (direction === "prev") {
        if (activeHorizontalSlide.value === 0) {
          // 첫 슬라이드에서 이전으로 이동 시 Section 3으로 이동
          activeSection.value = 2;
          scrollToSection(2, true);
        } else {
          activeHorizontalSlide.value--;
          scrollToHorizontalSlide(activeHorizontalSlide.value); // 이전 슬라이드로 이동
        }
      }

      setTimeout(() => (inMove.value = false), inMoveDelayHorizontal);
    };

    const scrollToHorizontalSlide = (slideIndex) => {
      const slide = document.querySelector(`.slide${slideIndex + 1}`);
      if (slide) {
        slide.scrollIntoView({ behavior: "smooth", inline: "center" });
      }
    };

    // 풀페이지 옵션 끝 ----------------------------------------------------------------------

    // Section1
    const currentDateTime = ref('');

    const updateDateTime = () => {
      const now = new Date();
      currentDateTime.value = `${now.getFullYear()}.${String(now.getMonth() + 1).padStart(2, '0')}.${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
    };

    // Section2
    const isFirstTextVisible = ref(false);
    const isSecondTextVisible = ref(false);
    const isDescriptionVisible = ref(false);
    const section2 = ref(null);

    // Section4
    const slidesData = [
      {
        boldTitle: "바이어 영접",
        title: "도 문제없이",
        description: "상담회가 시작되기 전, 바이어의 공항 도착 및 호텔 체크인 현황을 온라인으로 확인하세요.  관련된 업데이트를 운영 사무국과 현장 요원이 서로 공유하며, 즉각적으로 소통할 수 있습니다.",
        image: require('@/assets/img/landing/section4_img1.svg'),
      },
      {
        boldTitle: "통역사 관리",
        title: "도 효율적으로",
        description: "이제 온·오프라인 상담회에 투입될 통역사의 출결 · 근무 현황을 손쉽게 관리하세요. 통역사의 통역 언어, 담당 기업, 상담 테이블을 한눈에 파악할 수 있습니다. 만약의 경우에는 예비 통역사까지 신속하게 투입할 수 있죠.",
        image: require('@/assets/img/landing/section4_img2.svg'),
      },
      {
        boldTitle: "상담회 운영",
        title: "도 실시간으로",
        description: "이제 온·오프라인 상담회에 투입될 통역사의 출결 · 근무 현황을 손쉽게 관리하세요. 통역사의 통역 언어, 담당 기업, 상담 테이블을 한눈에 파악할 수 있습니다. 만약의 경우에는 예비 통역사까지 신속하게 투입할 수 있죠.",
        image: require('@/assets/img/landing/section4_img3.svg'),
      },
      {
        boldTitle: "실적 집계",
        title: "와 사후 관리까지",
        description: "한창 상담이 이루어지는 동안에도, 항목별로 실적을 집계 · 분석하거나 우수 상담일지를 선별해 보세요. 상담회가 끝나면, 결산 데이터를 다운로드하고, 바이어 만족도 설문조사와 같은 사후 관리까지 모두 진행할 수 있습니다.",
        image: require('@/assets/img/landing/section4_img4.svg'),
      }
    ];


    onMounted(() => {
      if (isAuthenticated.value) {
        if (accountType.value === "BUYER" || accountType.value === "INTERPRETER" || accountType.value === "SELLER") {
          router.push('/bis/dashboard')
        } else {
          router.push('/admin/event/select')
        }
      } else {
        router.push('/')
      }
      // 풀페이지 관련
      calculateSectionOffsets();
      window.addEventListener('DOMMouseScroll', handleMouseWheelDOM);
      window.addEventListener('mousewheel', handleMouseWheel, { passive: false });
      // Section1
      updateDateTime();
      setInterval(updateDateTime, 1000); // 매초마다 시간 업데이트

      // Section2
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          // 첫 번째 p 태그 보이기
          if (!isFirstTextVisible.value) {
            isFirstTextVisible.value = true;
          }

          // 2초 후 첫 번째 텍스트 숨기기, 두 번째 텍스트 보이기
          setTimeout(() => {
            if (isFirstTextVisible.value) {
              isFirstTextVisible.value = false;
              isSecondTextVisible.value = true; // 두 번째 텍스트 보이기
            }
          }, 2000); // 첫 번째 텍스트가 2초 후 사라지고 두 번째 텍스트가 나타납니다.

          // 3초 후 sec2_description과 버튼을 보이게 하기
          setTimeout(() => {
            if (isSecondTextVisible.value) {
              isDescriptionVisible.value = true; // sec2_description과 버튼을 보여줍니다.
            }
          }, 3000); // 3초 뒤

        } else {
          // 섹션을 벗어나면 모든 상태 초기화
          if (isFirstTextVisible.value || isSecondTextVisible.value || isDescriptionVisible.value) {
            isFirstTextVisible.value = false;
            isSecondTextVisible.value = false;
            isDescriptionVisible.value = false;
          }
        }
      }, {
        threshold: 0.8 // 화면에 80%가 보일 때 트리거
      });

      if (section2.value) {
        observer.observe(section2.value);
      }

    });


    onUnmounted(() => {
      window.removeEventListener('DOMMouseScroll', handleMouseWheelDOM);
      window.removeEventListener('mousewheel', handleMouseWheel);
    });

    return {
      activeSection,
      calculateSectionOffsets,
      handleMouseWheel,
      handleMouseWheelDOM,
      moveDown,
      moveUp,
      scrollToSection,
      inMove,
      offsets,

      currentDateTime,
      section2,
      isFirstTextVisible,
      isSecondTextVisible,
      isDescriptionVisible,
      moveHorizontal,
      activeSection,
      slidesData,
      activeHorizontalSlide,
    };
  }
}
</script>
<template>
  <div id="landing" v-show="windowSize.width >= 1024">
    <div class="sections-menu">
      <span class="menu-point" v-bind:class="{ active: activeSection == index }" v-on:click="scrollToSection(index)"
        v-for="(offset, index) in offsets" :key="index">
      </span>
    </div>
    <section class="fullpage section1" data-aos="fadeIn" data-aos-duration="1000">
      <p class="sec1_title size_58 b_700 t_center">수출 상담회의 기획과 운영, <br />이 모든걸 <span class="b_700 main_text">비샛</span>에서
      </p>
      <div class="ds_flex al_start jus_center sec1_cont_wrap">
        <div class="sec1_cont t_center">
          <p class="size_20 b_700">비샛을 통해 진행한 수출상담회</p>
          <p class="size_42 b_700 main_text">919<span class="size_32 main_text b_700">회</span></p>
        </div>
        <div class="sec1_cont t_center">
          <p class="size_20 b_700">비샛을 통해 기록한 상담</p>
          <p class="size_42 b_700 main_text">245,119<span class="size_32 main_text b_700">건</span></p>
        </div>
        <div class="sec1_cont t_center">
          <p class="size_20 b_700">비샛과 함께한 통역사</p>
          <p class="size_42 b_700 main_text">12,607<span class="size_32 main_text b_700">명</span></p>
          <p class="size_12 sub_text b_700 t_right">*실시간 데이터 {{ currentDateTime }} 기준</p>
        </div>
      </div>
      <div class="sec1_mouse ds_flex al_center gap16">
        <img src="@/assets/img/landing/section1_mouse.svg" />
        <transition name="arrow_down">
          <img src="@/assets/img/landing/section1_mouse_arrow.svg" class="mouse_arrow" />
        </transition>
      </div>
    </section>
    <section class="fullpage section2" ref="section2">
      <transition name="landing-fade">
        <p :class="{ 'active': isFirstTextVisible }" class="sec2_first_title size_48 t_center b_700 sub_text">
          데이터가 <span class="b_700">예측</span>하는<br />내 상담회의 <span class="b_700">성공 가능성</span>
        </p>
      </transition>
      <transition-group name="landing-fade">
        <div v-if="isSecondTextVisible" class="sec2_text_wrap">
          <p class="sec2_second_title size_48 t_center b_700">
            데이터가 <span class="main_text b_700">이끄는</span><br />내 상담회의 <span class="main_text b_700">성공</span>
          </p>
          <transition-group name="landing-fade">
            <div v-if="isDescriptionVisible">
              <p class="size_18 secondary_text sec2_description">
                성공적인 상담회 기획이 필요하세요?<br />
                2017년부터 최신 데이터까지​ 국내·외 수출상담회 빅데이터를 기반으로<br />
                AI가 예측한 모든 수출 상담회 데이터로 ##를 예측 분석할 수 있어요.
              </p>
              <p class="icon_cont_btn white_text back_primary size_18 b_700">
                수출상담회 실적 분석해보기
                <i class="icon-up_arrow rotate90 white_text size_18 b_700"></i>
              </p>
            </div>
          </transition-group>
        </div>
      </transition-group>
      <img :class="{ 'active': isDescriptionVisible }" src="@/assets/img/landing/section2_img.svg" />
    </section>
    <section class="fullpage section3 ds_flex jus_center al_center dir_column gap16">
      <div>
        <div class="sec3_dot" data-aos="fadeIn" data-aos-duration="1000" data-aos-delay="500"></div>
        <p class="size_48 b_700" data-aos="fadeIn" data-aos-duration="1000" data-aos-delay="500">비샛과 함께라면</p>
      </div>
    </section>
    <section class="fullpage section4">
      <div v-for="(slide, index) in slidesData" :key="index" class="slide" :class="`slide${index + 1}`">
        <div class="ds_flex al_center jus_start">
          <div class="sec4_text_wrap">
            <p class="num secondary_text"><span class="active_num">{{ `0${index + 1}` }}</span>/ 04</p>
            <h1 class="sec4_title size_36 b_700"><b class="main_text">{{ slide.boldTitle }}</b>{{ slide.title }}
            </h1>
            <p class="sec4_description size_18 secondary_text">{{ slide.description }}</p>
          </div>
        </div>
        <img :src="slide.image" alt="Slide Image" />
      </div>
      <template v-if="activeSection === 3 && activeHorizontalSlide !== 3">
        <img src="@/assets/img/landing/section1_mouse_arrow.svg" class="arrow right-arrow mouse_arrow" />
      </template>
    </section>
    <section class="fullpage section5">
      <div class="container ds_flex al_center dir_column jus_center">
        <p class="t_center size_40 b_700" data-aos="fadeIn" data-aos-duration="1000" data-aos-delay="500">상담회 시작
          전,<br />거래
          성사율을 높이는 <span class="SELLER_text b_700">스마트 매칭</span> 솔루션</p>
      </div>
    </section>
    <section class="fullpage section5">
      <div class="container ds_flex jus_center al_center dir_column gap20">
        <div class="sec5_cont1" data-aos="fadeIn" data-aos-duration="1000" data-aos-delay="500">
          <p class="size_32 b_700 white_text">성공적인 매칭이<br />성공적인 거래를 만들어요</p>
          <p class="white_text size_14">셀러가 상담을 원하는 바이어를 사전에 매칭 신청할 수 있어요.<br />
            만약 원하는 바이어가 없어도 괜찮아요.<br />
            비샛AI의 스마트 매칭을 통해 최적의 바이어를 연결해요.</p>
          <img src="@/assets/img/landing/section5_img1.svg" />
        </div>
        <div class="ds_flex jus_between cont_wrap" data-aos="fadeIn" data-aos-duration="1000" data-aos-delay="500">
          <div class="sec5_cont2 ds_flex al_end">
            <p class="white_text size_22 b_700">참가자의 비즈니스를 이해하는<br />비샛 AI의 스마트 매칭</p>
            <img src="@/assets/img/landing/section5_img2.svg" />
          </div>
          <div class="sec5_cont3 ds_flex al_end" data-aos="fadeIn" data-aos-duration="1000" data-aos-delay="500">
            <p class="white_text size_22 b_700">참가 신청부터 현장 운영까지<br />연결되는 비샛 데이터</p>
            <img src="@/assets/img/landing/section5_img3.svg" />
          </div>
        </div>
      </div>
    </section>
    <section class="fullpage section6">
      <div class="container ds_flex al_center dir_column jus_center">
        <div class="sec5_cont_wrap" data-aos="fadeIn" data-aos-duration="1000" data-aos-delay="500">
          <div class="sec5_text_wrap">
            <p class="size_18 b_700 eqqui_text">통역사 상담일지</p>
            <p class="size_32 b_700">스케줄 관리와<br />상담일지 관리를 한번에</p>
          </div>
          <img src="@/assets/img/landing/section6_img1.svg">
        </div>
        <div class="sec5_cont_wrap" data-aos="fadeIn" data-aos-duration="1000" data-aos-delay="500">
          <img src="@/assets/img/landing/section6_img2.svg">
          <div class="sec5_text_wrap">
            <p class="size_18 b_700 eqqui_text">바이어,셀러 현장 매칭</p>
            <p class="size_32 b_700">빈 시간,어느 때든<br />현장에서 연결되는 유연한 상담 기회 </p>
          </div>
        </div>
      </div>
    </section>
    <section class="fullpage section7">
      <div class="sec6_cont_wrap">
        <div class="sec6_text_wrap" data-aos="fadeIn" data-aos-duration="1000">
          <p class="size_48 b_700">더 넓은 시장을 개척하고,<br />새로운 기회를 함께 만들어 보세요.</p>
          <p class="size_22 secondary_text">이미 많은 국가 기관 및 주요 PCO 업체가 국내·외 다양한 수출상담회에서 비샛과 함께하고 있습니다.<br />
            수출상담회·전시회 종합 운영 플랫폼 비샛을 통해 ,여러분의 글로벌 비즈니스에 새로운 기회를 만들어 보세요.</p>
        </div>
        <Vue3Marquee>
          <img src="@/assets/img/landing/section7_img.svg" />
        </Vue3Marquee>
      </div>
    </section>
    <section class="fullpage section8">
      <div class="sec7_cont_wrap">
        <div class="sec7_text_wrap">
          <p class="footer_title">Where Buyers, Interpreters and Sellers meet @t a Trade show.</p>
          <a href="mailto:korea@eqqui.com" class="footer_btn">도입문의</a>
        </div>
        <img src="@/assets/img/landing/section8_img.svg" />
      </div>
      <div class="sec7_footer_wrap">
        <p class="size_14 secondary_text">eQQui, Inc. © 2015-2023</p>
        <div class="ds_flex jus_between al_center">
          <p class="size_14 secondary_text">에퀴코리아㈜</p>
          <p class="size_14 secondary_text">대표자: 박미순</p>
          <p class="size_14 secondary_text">korea@eqqui.com</p>
          <p class="size_14 secondary_text">서울특별시 서초구 서초중앙로 41(서초동, 대성빌딩) 7층</p>
          <p class="size_14 secondary_text">사업자등록번호: 401-86-00658</p>
          <p class="size_14 secondary_text">통신판매업신고번호: 2019-서초-0905호</p>
        </div>
      </div>
    </section>
  </div>
</template>
