<script>
import { ref, onMounted, computed, inject } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import subHeader from '@/components/admin/subHeader.vue';
import tabHeader from '@/components/admin/tabHeader.vue';

export default {
  name: 'preMatchScheduleTable',
  components: {
    Pagination,
    subHeader,
    tabHeader,
  },
  setup() {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const userName = ref('');
    const token = ref('');
    const activeSub = 'setting';
    const activeHeadTab = 'preMatch';


    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
        userName.value = store.state.userStore.userName;
        token.value = store.state.userStore.token;
      }
    });
    return {
      isAuthenticated,
      userName,
      activeSub,
      tabHeader,
      activeHeadTab,
    };
  },
}

</script>

<template>
  <div id="preMatchScheduleTable">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="mx_1160">
      <div class="header_tab_wrap">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
      </div>
      <h1>사전테이블</h1>
    </div>
  </div>
</template>
