<script>
import { ref, onMounted, computed, inject } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import subHeader from '@/components/admin/subHeader.vue';
import tabHeader from '@/components/admin/tabHeader.vue';
import country from '@/assets/js/bisatCountry'
import preMatchScheduleRequestModal from '@/components/admin/preMatchScheduleRequestModal.vue';

export default {
  name: 'preMatchScheduleRequest',
  components: {
    Pagination,
    subHeader,
    tabHeader,
    preMatchScheduleRequestModal
  },
  props: {
    event: Object
  },
  setup(props) {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const userName = computed(() => store.state.eventStore.userName);
    const userType = computed(() => store.state.userStore.accountType);
    const eventId = computed(() => store.state.eventStore.eventId);
    const { selectDate, handleSelectDate } = props.event;
    const token = ref('');
    const activeSub = 'setting';
    const activeHeadTab = 'preMatch';

    // PAGINATION 관련 변수
    const currentPage = ref(1);
    const itemsPerPage = ref(10); // 페이지당 아이템 수
    const totalPages = computed(() => Math.ceil(requestScheduleListTotal.value / itemsPerPage.value));
    const handleClickPage = (pageIndex) => {
      if (pageIndex >= 1 && pageIndex <= totalPages.value) {
        currentPage.value = pageIndex;
      }
    }

    // FUNCTION BAR
    const eventDateList = ref([]);
    // STEP1. 이벤트 날짜 리스트 받아오기
    async function fetchDate() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value
        });

        const url = `/schedule/read_date?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          eventDateList.value = response.data.date_list;
          if (!selectDate.value) {
            selectDate.value = eventDateList.value[0];
          }
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올때 오류:', error);
      }
    }

    const searchBy = ref('name_or_email')
    const searchText = ref('')

    const selectedStatuses = ref([])

    const requestScheduleListTotal = ref(3)
    const filteredRequestScheduleList = ref([])
    filteredRequestScheduleList.value = [
      {
        pta_id: 101,
        selected: false,
        company: "Tech Corp",
        full_name: "John Doe",
        country: "US",
        product: "Cloud Service A",
        request: "회사명 외 n건",
        status: '4/13', // 0: 처리 전, 1: 진행 중, 2: 완료
      },
      {
        pta_id: 102,
        selected: false,
        company: "Green Solutions",
        full_name: "Jane Smith",
        country: "US",
        product: "Eco-Friendly Software",
        request: "회사명 외 n건",
        status: '4/13', // 1: 진행 중
      },
      {
        pta_id: 103,
        selected: false,
        company: "Delta Systems",
        full_name: "David Lee",
        country: "US",
        product: "AI Analytics Tool",
        request: "회사명 외 n건",
        status: '4/13', // 2: 완료
      },
    ];
    const getCountryLabel = (value) => {
      const selectedCountry = country.find(item => item.code === value);
      return selectedCountry ? selectedCountry.label : value;
    };

    // Modal
    const requestUserModal = ref(false)
    const requestUserInfo = ref([])
    const openModal = (requestUser) => {
      requestUserInfo.value = requestUser;
      requestUserModal.value = true
    }
    const closeAllModals = () => {
      requestUserModal.value = false;
    };


    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
        token.value = store.state.userStore.token;
        await fetchDate()
      }
    });
    return {
      isAuthenticated,
      userName,
      userType,
      activeSub,
      tabHeader,
      activeHeadTab,
      currentPage,
      totalPages,
      handleClickPage,
      eventDateList,
      selectDate,
      handleSelectDate,

      searchText,
      searchBy,
      selectedStatuses,

      requestUserModal,
      requestUserInfo,
      openModal,
      closeAllModals,

      filteredRequestScheduleList,
      getCountryLabel
    };
  },
}

</script>

<template>
  <div id="preMatchScheduleRequest">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="mx_1160">
      <div class="header_tab_wrap">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
        <p class="inline_cont_btn">승인된 셀러 일괄 사용자 등록</p>
      </div>
      <div class="tables_wrap">
        <div class="function_bar">
          <div class="row">
            <select v-model="selectDate" @change="handleSelectDate()">
              <option v-for="(item, index) in eventDateList" :key="index" :value="item">{{ item }}</option>
            </select>
            <div class="cont_wrap ds_flex gap18 al_center">
              <p class="icon_box" @click="" v-if="userType !== 'C1' && userType !== 'C2'"><i class="icon-download"></i>
              </p>
              <div class="fake_textbox">
                <select v-model="searchBy">
                  <option value="name_or_email">이름/이메일</option>
                  <option value="company">회사명</option>
                  <option value="mobile">연락처</option>
                </select>
                <input type="text" v-model="searchText" placeholder="검색어 입력" @keyup.enter="onSearch()" />
                <template v-if="searchText != ''">
                  <i class="icon-delete secondary_text cs_pointer" @click="searchText = '';"></i>
                </template>
                <template v-else>
                  <i class="icon-search secondary_text cs_pointer"></i>
                </template>
              </div>
            </div>
          </div>
          <div class="row">
            <div class=" ds_flex gap8 al_center">
              <label class="size_14 secondary_text">상태 보기</label>
              <div class="status_wrap ds_flex gap10">
                <div class="ds_flex al_center">
                  <label for="checkbox-approve" class="fake_checkbox"
                    :class="{ 'active': selectedStatuses.includes('approve') }">
                    <i v-if="selectedStatuses.includes('approve')" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" id="checkbox-approve" value="approve" v-model="selectedStatuses" />
                  <label for="checkbox-approve" :class="{ 'active': selectedStatuses.includes('approve') }">승인
                    00</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table_wrap">
          <table class="table_view">
            <thead>
              <tr>
                <th>바이어</th>
                <th>국가</th>
                <th>품목</th>
                <th>상담요청</th>
                <th>승인현황</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="filteredRequestScheduleList.length > 0">
                <tr v-for="requestSchedule in filteredRequestScheduleList" :key="requestSchedule.pta_id"
                  @click="openModal(requestSchedule)">
                  <td class="name">
                    <strong>{{ requestSchedule.company }}</strong>
                    <br />
                    <span>{{ requestSchedule.full_name }}</span>
                  </td>
                  <td class="name">
                    <span>{{ getCountryLabel(requestSchedule.country) }}</span>
                  </td>
                  <td class="airline">
                    <span>{{ requestSchedule.product }}</span>
                  </td>
                  <td>
                    <span>{{ requestSchedule.request }}</span>
                  </td>
                  <td class="status">{{ requestSchedule.status }}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td>정보 없음</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <Pagination :currentPage="currentPage" :totalPages="totalPages" :handleClickPage="handleClickPage" />
      <pre-match-schedule-request-modal :request-user-modal="requestUserModal" :request-user-info="requestUserInfo"
        @close="closeAllModals"></pre-match-schedule-request-modal>
    </div>
  </div>
</template>
