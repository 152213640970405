<script>
import { ref, onMounted, computed, inject } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import subHeader from '@/components/admin/subHeader.vue';
import tabHeader from '@/components/admin/tabHeader.vue';
import preMatchEventRequestModal from '@/components/admin/preMatchEventRequestModal.vue';

export default {
  name: 'preMatchEventRequest',
  components: {
    Pagination,
    subHeader,
    tabHeader,
    preMatchEventRequestModal
  },
  setup() {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const userName = computed(() => store.state.eventStore.userName);
    const userType = computed(() => store.state.userStore.accountType);
    const token = ref('');
    const activeSub = 'setting';
    const activeHeadTab = 'preMatch';

    // PAGINATION 관련 변수
    const currentPage = ref(1);
    const itemsPerPage = ref(10); // 페이지당 아이템 수
    const totalPages = computed(() => Math.ceil(requestListTotal.value / itemsPerPage.value));
    const handleClickPage = (pageIndex) => {
      if (pageIndex >= 1 && pageIndex <= totalPages.value) {
        currentPage.value = pageIndex;
      }
    }

    // FUNCTION BAR
    const searchBy = ref('name_or_email')
    const searchText = ref('')

    const selectedStatuses = ref([])
    const changeAllStatusCheck = ref(false)
    const dropDownStatus = ref(false)
    const changeStatusValue = ref('')

    const requestListTotal = ref(3)
    const filteredRequestList = ref([])
    filteredRequestList.value = [
      {
        pta_id: 101,
        selected: false,
        company: "Tech Corp",
        full_name: "John Doe",
        date: "2024-11-19",
        product: "Cloud Service A",
        contact_email: "john.doe@example.com",
        mobile: "010-1234-5678",
        status: 0, // 0: 처리 전, 1: 진행 중, 2: 완료
      },
      {
        pta_id: 102,
        selected: false,
        company: "Green Solutions",
        full_name: "Jane Smith",
        date: "2024-11-20",
        product: "Eco-Friendly Software",
        contact_email: "jane.smith@example.com",
        mobile: "010-9876-5432",
        status: 1, // 1: 진행 중
      },
      {
        pta_id: 103,
        selected: false,
        company: "Delta Systems",
        full_name: "David Lee",
        date: "2024-11-21",
        product: "AI Analytics Tool",
        contact_email: "david.lee@example.com",
        mobile: "010-5678-1234",
        status: 2, // 2: 완료
      },
    ];

    const selectedPtaIds = ref([])
    const handleCheckboxChange = (request) => {
      if (request.selected) {
        // 체크되었을 경우 pta_id 추가
        if (!selectedPtaIds.value.includes(request.pta_id)) {
          selectedPtaIds.value.push(request.pta_id);
        }
      } else {
        // 체크 해제되었을 경우 pta_id 삭제
        selectedPtaIds.value = selectedPtaIds.value.filter(id => id !== request.pta_id);
      }
    };
    const toggleSelectAll = (checked) => {
      if (checked) {
        // 모두 선택하고 selectedPtaIds 업데이트
        selectedPtaIds.value = filteredRequestList.value.map(request => request.pta_id);
        filteredRequestList.value.forEach(request => request.selected = true);
      } else {
        // 모두 선택 해제
        selectedPtaIds.value = [];
        filteredRequestList.value.forEach(request => request.selected = false);
      }
    };

    // Modal
    const requestUserModal = ref(false)
    const requestUserInfo = ref([])
    const openModal = (requestUser) => {
      requestUserInfo.value = requestUser;
      requestUserModal.value = true
    }
    const closeAllModals = () => {
      requestUserModal.value = false;
    };


    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
        token.value = store.state.userStore.token;
      }
    });
    return {
      isAuthenticated,
      userName,
      userType,
      activeSub,
      tabHeader,
      activeHeadTab,
      currentPage,
      totalPages,
      handleClickPage,

      searchText,
      searchBy,
      selectedStatuses,
      changeAllStatusCheck,
      dropDownStatus,
      changeStatusValue,
      selectedPtaIds,
      handleCheckboxChange,
      toggleSelectAll,

      requestUserModal,
      requestUserInfo,
      openModal,
      closeAllModals,

      filteredRequestList,
    };
  },
}

</script>

<template>
  <div id="preMatchEventRequest" class="back_light_gray2">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="mx_1160">
      <div class="header_tab_wrap">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
        <p class="inline_cont_btn">승인된 셀러 일괄 사용자 등록</p>
      </div>
      <div class="tables_wrap">
        <div class="function_bar">
          <div class="row goal">
            <p class="size_20 t_center"><span class="main_text b_700">20%</span> 도달</p>
            <div class="t_center">
              <p class="size_14 secondary_text">목표 셀러</p>
              <p class="size_18 b_700">360개 업체</p>
            </div>
            <div class="t_center">
              <p class="size_14 secondary_text">승인된 셀러</p>
              <p class="size_18 b_700">24<span class="sub_text b_700">/30개 업체</span></p>
            </div>
          </div>
          <div class="row">
            <p class="b_700">참여 신청 리스트</p>
            <div class="cont_wrap ds_flex gap18 al_center">
              <p class="icon_box" @click="" v-if="userType !== 'C1' && userType !== 'C2'"><i class="icon-download"></i>
              </p>
              <div class="fake_textbox">
                <select v-model="searchBy">
                  <option value="name_or_email">이름/이메일</option>
                  <option value="company">회사명</option>
                  <option value="mobile">연락처</option>
                </select>
                <input type="text" v-model="searchText" placeholder="검색어 입력" @keyup.enter="onSearch()" />
                <template v-if="searchText != ''">
                  <i class="icon-delete secondary_text cs_pointer" @click="searchText = '';"></i>
                </template>
                <template v-else>
                  <i class="icon-search secondary_text cs_pointer"></i>
                </template>
              </div>
            </div>
          </div>
          <div class="row">
            <div class=" ds_flex gap8 al_center">
              <label class="size_14 secondary_text">상태 보기</label>
              <div class="status_wrap ds_flex gap10">
                <div class="ds_flex al_center">
                  <label for="checkbox-approve" class="fake_checkbox"
                    :class="{ 'active': selectedStatuses.includes('approve') }">
                    <i v-if="selectedStatuses.includes('approve')" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" id="checkbox-approve" value="approve" v-model="selectedStatuses" />
                  <label for="checkbox-approve" :class="{ 'active': selectedStatuses.includes('approve') }">승인
                    00</label>
                </div>
                <div class="ds_flex al_center">
                  <label for="checkbox-return" class="fake_checkbox"
                    :class="{ 'active': selectedStatuses.includes('return') }">
                    <i v-if="selectedStatuses.includes('return')" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" id="checkbox-return" value="return" v-model="selectedStatuses" />
                  <label for="checkbox-return" :class="{ 'active': selectedStatuses.includes('return') }">반려 00</label>
                </div>
                <div class="ds_flex al_center">
                  <label for="checkbox-none" class="fake_checkbox"
                    :class="{ 'active': selectedStatuses.includes('none') }">
                    <i v-if="selectedStatuses.includes('none')" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" id="checkbox-none" value="none" v-model="selectedStatuses" />
                  <label for="checkbox-none" :class="{ 'active': selectedStatuses.includes('none') }">미확인 00</label>
                </div>
              </div>
              <p class="size_14 secondary_text b_700">2024-11-19 <span class="main_text">00</span></p>
              <p class="size_14 secondary_text b_700">2024-11-20 <span class="main_text">00</span></p>
            </div>
            <p class="inline_cont_btn change_all_status" @click="changeAllStatusCheck = true"
              v-if="!changeAllStatusCheck">상태 일괄
              변경하기</p>
          </div>
          <div v-if="changeAllStatusCheck" class="ds_flex jus_between al_center change_all_status_info">
            <p class="size_14 secondary_text">상태를 변경할 바이어를 선택하세요.</p>
            <div class="change_all_status_wrap ds_flex al_center gap12">
              <p class="size_14 eqqui_text b_700">{{ selectedPtaIds.length }}명</p>
              <div>
                <p class="inline_cont_btn" @click="dropDownStatus = true">변경할 상태 선택</p>
                <div class="dropdown_status_wrap" v-if="dropDownStatus">
                  <label for="approve" class="ds_flex gap8 al_center dropdown_status_cont"
                    :class="{ 'active': changeStatusValue === 'approve' }">
                    <input type="radio" value="approve" id="approve" name="status" v-model="changeStatusValue" />
                    <span class="size_14">승인</span>
                  </label>
                  <label for="return" class="ds_flex gap8 al_center dropdown_status_cont"
                    :class="{ 'active': changeStatusValue === 'return' }">
                    <input type="radio" value="return" id="return" name="status" v-model="changeStatusValue" />
                    <span class="size_14">반려</span>
                  </label>
                  <div class="btn_wrap ds_flex jus_between al_center">
                    <p class="submit" @click="">변경하기</p>
                  </div>
                </div>
              </div>
              <p @click="changeAllStatusCheck = false" class="size_12 sub_text pointer">취소</p>
            </div>
          </div>
        </div>
        <div class="table_wrap">
          <table class="table_view">
            <thead>
              <tr>
                <th v-if="changeAllStatusCheck">
                  <label class="fake_checkbox" for="selectAll" :class="{ 'active': selectAll }">
                    <i v-if="selectAll" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" id="selectAll" v-model="selectAll" @change="toggleSelectAll(selectAll)" />
                </th>
                <th>셀러</th>
                <th>신청일</th>
                <th>품목</th>
                <th>이메일</th>
                <th>연락처</th>
                <th>상태</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="filteredRequestList.length > 0">
                <tr v-for="request in filteredRequestList" :key="request.pta_id" @click="openModal(request)">
                  <!-- 체크박스 -->
                  <td v-if="changeAllStatusCheck">
                    <label class="fake_checkbox" :for="'select_' + request.pta_id"
                      :class="{ active: request.selected }">
                      <i v-if="request.selected" class="icon-checked"></i>
                    </label>
                    <input type="checkbox" :id="'select_' + request.pta_id" v-model="request.selected"
                      @change="handleCheckboxChange(request)" />
                  </td>

                  <!-- 회사 이름 및 담당자 이름 -->
                  <td class="name">
                    <strong>{{ request.company }}</strong>
                    <br />
                    <span>{{ request.full_name }}</span>
                  </td>

                  <!-- 접수 날짜 -->
                  <td>
                    <span>{{ request.date }}</span>
                  </td>

                  <!-- 제품명 -->
                  <td class="airline">
                    <span>{{ request.product }}</span>
                  </td>

                  <!-- 연락처 -->
                  <td>
                    <span>{{ request.contact_email }}</span>
                  </td>
                  <!-- 연락처 -->
                  <td>
                    <span>{{ request.mobile }}</span>
                  </td>

                  <!-- 상태 -->
                  <td class="status" @click.stop="">
                    <span :class="{
                      'status_pending': request.status === 0,
                      'status_complete': request.status === 1,
                      'status_return': request.status === 2,
                    }">
                      {{
                        request.status === 0
                          ? '미확인'
                          : request.status === 1
                            ? '승인'
                            : '반려'
                      }}
                    </span>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td>정보 없음</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <Pagination :currentPage="currentPage" :totalPages="totalPages" :handleClickPage="handleClickPage" />
      <pre-match-event-request-modal :request-user-modal="requestUserModal" :request-user-info="requestUserInfo"
        @close="closeAllModals"></pre-match-event-request-modal>
    </div>
  </div>
</template>
