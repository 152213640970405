<script>
import { ref, onMounted, computed, inject } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import subHeader from '@/components/admin/subHeader.vue';
import tabHeader from '@/components/admin/tabHeader.vue';

export default {
  name: 'preMatchSetting',
  components: {
    Pagination,
    subHeader,
    tabHeader,
  },
  setup() {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const userName = computed(() => store.state.eventStore.userName);
    const userType = computed(() => store.state.userStore.accountType);
    const token = ref('');
    const activeSub = 'setting';
    const activeHeadTab = 'preMatch';

    // 사전주선 기본정보
    const preMatchSetting = ref({})
    preMatchSetting.value.request_link = "https://bisat.eqqui.com/djasdflkajdlkjdfj"
    preMatchSetting.value.buyer_total = "130"
    preMatchSetting.value.seller_goal = "360"


    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
        token.value = store.state.userStore.token;
      }
    });
    return {
      isAuthenticated,
      userName,
      userType,
      activeSub,
      tabHeader,
      activeHeadTab,
      preMatchSetting
    };
  },
}

</script>

<template>
  <div id="preMatchSetting">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="mx_1160">
      <div class="header_tab_wrap">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
      </div>
      <div class="cont_wrap ds_flex dir_column gap36">
        <div class="ds_flex al_center gap18">
          <label>참여자 신청 링크</label>
          <div class="ds_flex al_center gap12">
            <input class="fake_textbox" type="text" disabled v-model="preMatchSetting.request_link"></input>
            <p class="inline_cont_btn cancel_btn">복사</p>
          </div>
        </div>
        <div class="ds_flex al_center gap18">
          <label>등록 바이어 수</label>
          <div class="ds_flex al_center gap12">
            <input class="fake_textbox" type="text" v-model="preMatchSetting.buyer_total"/>개 업체
          </div>
        </div>
        <div class="ds_flex al_center gap18">
          <label>셀러 참여 목표 인원</label>
          <div class="ds_flex al_center gap12">
            <input class="fake_textbox" type="text" v-model="preMatchSetting.seller_goal"/>개 업체
          </div>
        </div>
      </div>
    </div>
    <div class="floating_btn">
      <div class="mx_1160">
        <div class="ds_flex">
          <p class="block_main_btn delete" @click="">삭제</p>
          <p class="block_main_btn save" @click="" >저장</p>
        </div>
      </div>
    </div>
  </div>
</template>
